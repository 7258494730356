import React from 'react';
import { ParentSize } from '@visx/responsive';
import { AreaClosed, LinePath, Line } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AnimatedAxis } from '@visx/react-spring';
import { bisector, max, extent } from 'd3-array';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import CircularProgress from '@mui/material/CircularProgress';
import { GridRows, GridColumns } from '@visx/grid';
import moment from 'moment';

const PlayerChart = ({ data, timeframe, dataKey, keyName, loading }) => {
  const [yItem, setYItem] = React.useState(0);
  const {
    tooltipOpen,
    tooltipData,
    tooltipTop,
    tooltipLeft,
    showTooltip,
    hideTooltip
  } = useTooltip();

  if (!data.length) {
    return <p>No data available for this timeframe</p>;
  }
  const getGradientColor = (start, end, key) => {
    if(key === '_int' || key === 'sk' || key === 'ltt' || key === 'lttPercent' || key === 'ckdn' || key === 'ckdnPercent' || key==="skPercent" || key==="intPercent" ) {
      return  start > end ? 'green' : start < end ? 'red' : 'grey';
    } else {
      return  end > start ? 'green' : end < start ? 'red' : 'grey';
    }
  }
  const isGame = !!data[0].index;
  return (
    <ParentSize>
      {({ width, height }) => {
        height = Math.max(175, height);

        const margin = { top: 20, right: 50, bottom: 50, left: 80 };
        const xMax = width - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;

        const xScale = isGame
          ? scaleLinear({
              range: [0, xMax],
              domain: [1, data?.length]
            })
          : scaleTime({
              range: [0, xMax],
              domain: extent(data, d => new Date(d.date))
            });
        const yScale = scaleLinear({
          range: [yMax, 0],
          domain: [0, max(data, d => d?.[dataKey]) || 0]
        });

        const startScore = data[0]?.[dataKey];
        const endScore = data[data.length - 1]?.[dataKey];
        const gradientId = getGradientColor(startScore, endScore, dataKey);

        const handleTooltip = (event) => {
          let { x, y } = localPoint(event) || { x: 0, y: 0 };
          x -= margin.left;
          y -= margin.top;
          const x0 = xScale.invert(x);
          let d;
          if (isGame) {
            d = data[Math.round(x0 - 1)];
          } else {
            const index = bisector(d => new Date(d.date)).left(data, x0, 1);
            d = index > 0 ? data[index - 1] : data[0];
          }
          setYItem(y);
          console.log('here', d)
          showTooltip({
            tooltipData: d,
            tooltipLeft: xScale(isGame ? d.index : new Date(d.date)),
            tooltipTop: yScale(d?.[dataKey])
          });
        };

        const fontSize = width < 1000 ? '16px' : '20px';
        const isMobile = width < 1000;
        return (
          <div style={{ position: 'relative' }}>
            {loading && 
             <div className="chart_loading_container" style={{width, height: height+ (isMobile ? 6 : 5)}}>
             <CircularProgress style={{ color: '#1A283A' }} size={'100px'} />
           </div>}
            <svg width={width} height={height}>
              <defs>
                <LinearGradient id="green" from="#76c893" to="#76c893" toOpacity={0.1} />
                <LinearGradient id="red" from="#ff6f69" to="#ff6f69" toOpacity={0.1} />
                <LinearGradient id="grey" from="#ccc" to="#ccc" toOpacity={0.1} />
              </defs>
              <g transform={`translate(${margin.left}, ${margin.top})`}>
                <GridRows scale={yScale} width={xMax} strokeDasharray="2,2" stroke="#878686" />
                <GridColumns scale={xScale} height={yMax} strokeDasharray="2,2" stroke="#878686" />
                <AreaClosed
                  data={data}
                  x={d => isGame ? xScale(d?.index) : xScale(new Date(d.date))}
                  y={d => yScale(d?.[dataKey])}
                  yScale={yScale}
                  strokeWidth={2}
                  stroke={`url(#${gradientId})`}
                  fill={`url(#${gradientId})`}
                  curve={curveMonotoneX}
                />
                <LinePath
                  data={data}
                  x={d => isGame ? xScale(d?.index) : xScale(new Date(d.date))}
                  y={d => yScale(d?.[dataKey])}
                  stroke={gradientId === 'green' ? 'green' : gradientId === 'red' ? 'red' : 'grey'}
                  strokeWidth={4}
                  curve={curveMonotoneX}
                />
                <AnimatedAxis
                  numTicks={width > 1000 ? 8 : width < 525 ? 3 : 5}
                  orientation="bottom"
                  scale={xScale}
                  top={yMax}
                  tickLabelProps={() => ({ fontSize: 16, dx: '-1.25em' })}
                />
                <AnimatedAxis
                  numTicks={height > 1000 ? 8 : height < 525 ? 3 : 5}
                  orientation="left"
                  scale={yScale}
                  tickLabelProps={() => ({ fontSize: 16, dx: '-3em', dy: '0.25em' })}
                />
                {tooltipOpen && (
                  <g>
                    <Line
                      from={{ x: tooltipLeft, y: 0 }}
                      to={{ x: tooltipLeft, y: yMax }}
                      stroke="rgba(0, 0, 0, 1)"
                      strokeWidth={2}
                      pointerEvents="none"
                      strokeDasharray="3,3"
                    />
                    <circle
                      cx={tooltipLeft}
                      cy={tooltipTop}
                      r={5}
                      fill={gradientId === 'green' ? 'green' : gradientId === 'red' ? 'red' : 'grey'}
                      stroke="white"
                      strokeWidth={2}
                    />
                  </g>
                )}
              </g>
              <rect
                x={margin.left}
                y={margin.top}
                width={xMax}
                height={yMax}
                fill="transparent"
                onMouseMove={handleTooltip}
                onMouseLeave={hideTooltip}
                onTouchMove={handleTooltip}
              />
            </svg>
            {tooltipOpen && (
              <TooltipWithBounds
                key={Math.random()}
                top={yItem - 12}
                left={tooltipLeft + margin.left}
                style={{
                  ...defaultStyles,
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  border: '1px solid rgba(255,255,255,.7)',
                  color: 'white',
                  padding: '0px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: fontSize }}>
                  {!isGame && (
                    <div style={{ display: 'flex', gap: fontSize }}>
                      <div style={{ fontSize: fontSize, width: '70px', textAlign: 'end' }}>Date: </div>
                      <div style={{ fontSize: fontSize }}>
                        {timeframe === '1 Day' || timeframe === '1 Week'
                          ? moment(tooltipData.date)?.format('M/D/YYYY hh:mm A')
                          : timeframe === '1 Month'
                          ? moment(tooltipData.date)?.format('M/D/YYYY h A')
                          : new Date(tooltipData.date).toLocaleDateString()}
                      </div>
                    </div>
                  )}
                  {!!isGame && (
                    <div style={{ display: 'flex', gap: fontSize }}>
                      <div style={{ fontSize: fontSize, width: '80px', textAlign: 'end' }}>Game #: </div>
                      <div style={{ fontSize: fontSize }}>{tooltipData?.index}</div>
                    </div>
                  )}
                  <div style={{ display: 'flex', gap: fontSize }}>
                    <div style={{ fontSize: fontSize, width: '80px', textAlign: 'end' }}>{keyName}:</div>
                    <div style={{ fontSize: fontSize }}>{Math.round((tooltipData?.[dataKey] ?? 0) * 100)/100}</div>
                  </div>
                </div>
              </TooltipWithBounds>
            )}
          </div>
        );
      }}
    </ParentSize>
  );
};

export default PlayerChart;
