import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Link, Snackbar, Alert, Slide, IconButton, InputAdornment, SnackbarCloseReason, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './styles.css';
import { useCoachSignInMutation, useGetSelfLazyQuery } from '../../services/omahaApi/__generated__/graphql';
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

// Slide transition function
function SlideTransition(props: any) {
  return <Slide {...props} direction="up" />;
}

const LoginPage = () => {
  const [signIn, { loading }] = useCoachSignInMutation(); // Use loading state
  const [getSelf, { data: selfData, loading: selfLoading, error: selfError }] = useGetSelfLazyQuery();
  
  const navigate = useNavigate();

  // State for managing Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  // Validation schema for form
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .matches(/^[\w\-\.]+(\+[\w\-\.]+)?@([\w\-]+\.)+[\w\-]{2,4}$/, 'Enter a valid email')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Perform login logic (e.g., API call)
      if (values.email && values.password) {
        coachSignIn(values.email, values.password);
      }
    },
  });

  const coachSignIn = async (email: string, password: string) => {
    try {
      const res = await signIn({
        variables: {
          email,
          password,
        },
      });
      console.log(res.data?.coachSignIn);
      window.localStorage.setItem('user', JSON.stringify(res.data?.coachSignIn ?? '{}'));
      window.location.href = '/';
    } catch (error: any) {
      console.error(error);
      setErrorMessage(error.message || 'Failed to sign in');
      setSnackbarOpen(true); // Open the Snackbar with the error message
    }
  };

  // Snackbar onClose handler with two parameters (event and reason)
  const handleSnackbarClose = (
    event: Event | SyntheticEvent<any, Event>, // Allow both Event and SyntheticEvent
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Alert onClose handler
  const handleAlertClose = (event: SyntheticEvent<any, Event>) => {
    setSnackbarOpen(false);
  };

  // Auto-login using the getSelf query if token exists
  useEffect(() => {
    const storedUser = JSON.parse(window.localStorage.getItem('user') || '{}');
    const accessToken = storedUser?.accessToken;
    console.log('hererere', storedUser)
    if (accessToken) {
      getSelf(); // Trigger getSelf query
    }
  }, [getSelf]);

  // If self query succeeds, redirect to homepage
  useEffect(() => {
    if (selfData && selfData.self) {
      navigate('/');
    }
  }, [selfData, navigate]);

  // Handle query failure
  useEffect(() => {
    if (selfError) {
      console.error('Auto login failed:', selfError);
    //   window.localStorage.removeItem('user'); // Remove invalid user from storage
    }
  }, [selfError]);

  return (
    <Box className="login_container">
      <Box
        sx={{
          width: '400px',
          padding: '40px',
          backgroundColor: 'white',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: '20px', fontWeight: 500 }}>
          Account Log In
        </Typography>
        <Box component="form" sx={{ mt: 2 }} onSubmit={formik.handleSubmit}>
          <Typography variant="body1" sx={{ textAlign: 'left', mb: 1 }}>
            Email
          </Typography>
          <TextField
            fullWidth
            id="email"
            name="email"
            variant="outlined"
            placeholder="enter email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{ mb: 3 }}
          />
          <Typography variant="body1" sx={{ textAlign: 'left', mb: 1 }}>
            Password
          </Typography>
          <TextField
            fullWidth
            id="password"
            name="password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'} // Toggle between text and password
            placeholder="******"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            sx={{ mb: 4 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || selfLoading} // Disable the button when loading
            sx={{
              height: '50px',
              fontSize: '18px',
              backgroundColor: '#1e73be',
              '&:hover': { backgroundColor: '#155a8f' },
            }}
          >
            {loading || selfLoading ? <CircularProgress size={24} /> : 'LOG IN'} {/* Show loader or text */}
          </Button>
          <Box sx={{ marginTop: '16px' }}>
            <Link href="#" variant="body2" sx={{ color: '#1e73be' }}>
              Forgot Password?
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '20px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 30px',
        }}
      >
        <Typography sx={{ fontWeight: 600 }}>CRUCIBLE QB COACH PORTAL</Typography>
        <Box>
          <Link href="#" variant="body2" sx={{ marginRight: '16px', color: '#000' }}>
            Terms of Service
          </Link>
          <Link href="#" variant="body2" sx={{ color: '#000' }}>
            Privacy Policy
          </Link>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoginPage;
